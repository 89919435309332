<template>
  <v-parallax src="@/assets/images/pages/signup-bg.png" height="100%">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col align-self="center" cols="12" sm="6">
          <Logo/>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined class="pa-3" height="500">
            <v-card-text class="pt-3">
              <h2>Create an account</h2>
              <p class="mt-0">Already have an account? 
                <router-link to="/signin">
                  <span>Sign in</span>
                </router-link>
              </p>
              <h4 class="my-5">Sign up with email</h4>

              <v-form class="mt-4" ref="registerForm" @submit.prevent="handleRegisterSubmitClick()">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Account name"
                      type="text"
                      v-model="registerForm.name"
                      placeholder="Doers"
                      dense
                      :rules="[
                        required('Account Name'),
                        (v) => v.length > 2 || 'Name at least 3 character',
                      ]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-2">
                    <v-text-field
                      label="E-mail"
                      type="email"
                      v-model="registerForm.email"
                      placeholder="admin@doers.com"
                      dense
                      :rules="[required('Email'), email('Email')]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-2">
                    <v-text-field
                      dense
                      v-model="registerForm.first_name"
                      label="First name"
                      type="text"
                      placeholder="Jonh"
                      autocomplete="off">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-2">
                    <v-text-field
                      dense
                      v-model="registerForm.last_name"
                      label="Last name"
                      type="text"
                      placeholder="Smith"
                      autocomplete="off">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-2">
                    <v-text-field
                      dense
                      label="Password"
                      v-model="registerForm.password"
                      placeholder="Password"
                      :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="[
                        required('Password'),
                        password('Password'),
                      ]"
                      @click:append="showPassword = !showPassword">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-btn
                    absolute
                    small
                    right
                    class="my-3 mx-3 rounded-pill"
                    color="primary"
                    type="submit"
                    :disabled="registerForm.name.length > 2 && registerForm.email ? false : true"
                  >Continue</v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { REGISTER } from "@/store/_actiontypes";
import validations from "@/utils/validations";
import Logo from "@/components/common/Logo.vue"
import { mdiEye, mdiEyeOff } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiEye,
        mdiEyeOff,
      }
    }
  },
  components: {
    Logo
  },
  created(){
    this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int;
  },
  data(){
    return {
      showPassword: false,
      registerForm: {
        name: "",
        email: "",
        password: "",
        first_name: "",
        last_name: "",
      },
      ...validations,
    };
  },
  watch: {
    "registerForm.name": function (){
      if(!this.registerForm.name){
        this.registerForm.password = "";
      }
    },
    "registerForm.email": function (){
      if(!this.registerForm.email){
        this.registerForm.password = "";
      }
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      signup_error: (state) => state.account.signup_error,
    }),
  },
  mounted(){
    if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
  },
  methods: {
    ...mapActions("account", [REGISTER]),
    handleRegisterSubmitClick(){
      if(!this.$refs.registerForm.validate()) return;

      if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
        grecaptcha.execute(this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {
          action: "register"
        }).then((recaptcha_token) => {
          this.handleRegisterSubmit(recaptcha_token);
        });
      } else{
        this.handleRegisterSubmit(null);
      }
    },
    handleRegisterSubmit(recaptcha_token){
      const { name, email, first_name, last_name, password } = this.registerForm;
      this.REGISTER({ name, email, first_name, last_name, password, recaptcha_token }).then((res) => {
        this.$router.push({path: "/register-success", query: {email: email}});
      }, (err) => {
        console.log(err)
      });
    },
  },
};
</script>